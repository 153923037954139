.DownloadLink_Container {
  /* margin: 2% 0 2% 11%; */
}

.DownloadLink_Container h2 {
  color: #0c80a0;
  font-size: 1.1rem;
  font-weight: 600;
}

.DownloadLink_Container p {
  color: #1c1c1c;
  font-size: 0.8rem;
  font-weight: 400;
  width: 25%;
}

.DownloadLink_StoreDiv {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.DownloadLink_StoreDiv img {
  width: 10%;
  margin-right: 1%;
}

@media only screen and (max-width: 1366px) {
  .DownloadLink_Container h2 {
    font-size: 1.2rem;
  }

  .DownloadLink_Container p {
    font-size: 0.9rem;
    width: 35%;
  }

  .DownloadLink_StoreDiv img {
    width: 12%;
  }
}

@media only screen and (max-width: 900px) {
  /* .DownloadLink_Container {
    margin: 5% 0 5% 10%;
  } */

  .DownloadLink_Container h2 {
    font-size: 1.3rem;
  }

  .DownloadLink_Container p {
    width: 50%;
  }

  .DownloadLink_StoreDiv img {
    width: 20%;
  }
}

@media only screen and (max-width: 600px) {
  .DownloadLink_Container h2 {
    font-size: 1.2rem;
  }

  .DownloadLink_Container p {
    font-size: 0.9rem;
    width: 80%;
  }

  .DownloadLink_StoreDiv img {
    width: 30%;
  }
}